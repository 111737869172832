<template>
    <div class="error-404">
        <div class="error-404__text">
            <h1>Welp!, nothing to do here...</h1>
            <p>
                Got yourself in the wrong page? You can return to our
                <router-link to="/">homepage</router-link>
                or try again later.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>

<style scoped lang="scss">
.error-404 {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    background: #74492d url('~Images/error-404.jpg') center no-repeat;
    background-size: cover;
    height: 100vh;

    @media (max-width: $xl-down) {
        background-image: url('~Images/error-404-xl.jpg');
    }

    @media (max-width: $lg-down) {
        background-image: url('~Images/error-404-md.jpg');
    }

    @media (max-width: $sm-down) {
        background-image: url('~Images/error-404-xs.jpg');
    }
}

.error-404__text {
    font-size: 1rem;
    margin-bottom: em(75);
    text-align: center;
    color: #fff;

    h1 {
        margin: 0 0 em(40, 32);
        font-size: em(32);
    }

    p {
        margin: 0;
        font-size: em(24);
    }

    a {
        color: $yellow-main;
        text-decoration: underline;
    }

    @media (max-width: $lg-down) {
        max-width: 70%;
        margin-bottom: 20%;

        h1 {
            font-size: em(32);
            margin-bottom: 1em;
        }

        p {
            font-size: em(22);
        }
    }

    @media (max-width: $xs-down) {
        max-width: 85%;
        //margin-bottom: 20%;

        h1 {
            font-size: em(19);
            margin-bottom: 1em;
        }

        p {
            font-size: em(16);
        }
    }
}
</style>
